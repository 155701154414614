/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "/node_modules/react-modal-video/scss/modal-video.scss";
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap"); */

@layer base {
  html {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    background-color: black !important;
    overflow-x: hidden;
  }
  body {
    /* @apply bg-navy; */
    position: relative;
    left: 0;
    right: 0;
    min-width: 320px;
    max-width: 1768px !important;
    width: 100vw;
    overflow-x: hidden;
  }

  p {
    font-size: 20px !important;
  }
  p,
  button {
    font-family: "Shapiro45Wetler", "Poppins", sans-serif;
    line-height: 1.3 !important;
    font-weight: 500 !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Shapiro45Wetler", "Poppins", sans-serif;
    /* font-family: Shapiro45Welter; */
    line-height: 1.3 !important;
    font-weight: 900 !important;
  }
}

#swiper-style {
  display: flex !important;
  align-items: flex-start !important;
}

@media (max-width: 768px) {
  /* .swiper {
    min-height: 300px !important;
  } */
  #trailer-mobile {
    font-size: 50px !important;
  }
}
@media (max-width: 640px) {
  #comingsoon-mobile {
    width: 100% !important;
    margin-bottom: 40px !important;
  }
  #textMobile {
    font-size: 3.2rem !important;
  }
  .mobileView {
    width: 100%;
  }
}
@layer components {
  .text-gradient-primary {
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(135deg, #05c3de, #f93822);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
  }

  .button {
    @apply py-2 px-6;

    /* @apply border border-solid rounded; */
    @apply duration-300;
  }

  .button-outline:hover {
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }

  .button-outline {
    border: 1px solid white;
    color: white;
    @apply rounded-3xl;
    @apply hover:border-green-500 hover:text-green-500;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  /* .button-outline {
    @apply border-pink hover:border-purple;
    @apply text-pink hover:text-purple;
  } */
  .button-outline2 {
    border: 1px solid white;
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    @apply rounded-3xl;
    @apply border-white hover:border-red-500;
    @apply text-white hover:text-red-500;
  }
  .button2 {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    @apply py-2 px-20;
    @apply duration-300;
  }
  .button-outlineN {
    border: 1px solid black;
    color: black;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    @apply hover:border-green-500 hover:text-green-500;
  }
  .button-primary {
    --tw-gradient-from: #ffffff;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-to) 35%;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    color: #ffffff;
    outline-offset: 2px;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    @apply rounded-3xl;
  }
  .button-primary:hover {
    --tw-gradient-from: #ffffff;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-to) 35%;
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .button-primary2 {
    border: 0.5px solid red;
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    /* border: 2px solid black; */
    /* box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    /* -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    @apply hover:bg-black;
    @apply rounded-3xl;
    @apply text-white;
  }
  .button-primary2:hover {
    border: 0.5px solid #000000;
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .nav-button-pre {
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    /* border: 2px solid black; */
    /* box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    /* -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    @apply hover:bg-black;
    @apply rounded-3xl;
    @apply text-white;
  }
  .nav-button-pre:hover {
    --tw-gradient-from: #ff0000;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .button-primary2:hover {
    border: 1px solid red;
  }
  .nav-button-signin {
    --tw-gradient-from: #ffffff;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-to) 35%;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 1px solid #000000;
    outline: 2px solid transparent;
    outline-offset: 2px;
    /* border: 2px solid black; */
    /* box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    /* -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
    @apply hover:bg-red-600;
    @apply rounded-3xl;
    @apply text-white;
  }
  .nav-button-signin:hover {
    --tw-gradient-from: #ffffff;
    --tw-gradient-to: #000000;
    --tw-gradient-stops: var(--tw-gradient-from) 0%, var(--tw-gradient-to) 35%;
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .menu-toggle-nonactive {
    @apply duration-500 opacity-0 invisible lg:opacity-100 lg:visible;
  }

  .menu-toggle-active {
    @apply duration-500 opacity-100 visible lg:opacity-100 lg:visible;
  }
}

.home-hero-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.collection-video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 320px !important;
}

.collection-video-container video {
  width: 100%;
}

.home-hero-container video {
  position: relative;
  object-fit: cover;
}

.background-video {
  position: relative;
  width: 100%;
  min-height: 700px;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.background-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-other-video {
  position: relative;
  width: 100%;
  height: 300px !important;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.background-other-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-digital-video {
  position: relative;
  width: 100%;
  min-height: 700px;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.background-digital-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auction-background-video {
  position: relative;
  width: 100%;
  height: 300px !important;
  overflow: hidden;
}

.auction-background-video video {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-whitespace-svg {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}


#swiper-style {
  height: auto !important;
  max-height: fit-content !important;
}
 @media (max-width: 768px) {
  .tabel-mobile {
    overflow-x: scroll !important;
  }
  .swiper-container {
    width: 100% !important; /* Adjust this value as needed */
    flex-shrink: 0 !important;
  }
  .background-digital-video {
    position: relative;
    width: 100%;
    min-height: 700px !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
 }

.swiper-container {
  width: 50%; /* Adjust this value as needed */
  flex-shrink: 0 !important;
}
.v-card {
  height: 370px !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.textHolder {
  left: 35%;
  top: 67%;
}

#unique-flex-row {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  --scroll-duration: 9s; /* Adjust the duration as per your needs */
}
@media (max-width: 420px) {
  .textHolder {
    top: 62%;
    left: 12%;
  }
}
#unique-flex-row > * {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  scroll-snap-coordinate: 0% 0%;
  animation: scroll-animation var(--scroll-duration) linear infinite;
}

@keyframes scroll-animation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/* ul {
  transition: opacity 0.8s ease;
  opacity: 0;
}

ul.open {
  opacity: 1;
} */

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-slide-from-left {
  animation: slideFromLeft 0.5s forwards;
}

/* Animation for the elements dropping from top to bottom */
@keyframes droppingAnimation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.dropping-animation {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  animation: droppingAnimation 3s infinite ease-in-out;
}

/* Smoke effect rising from the bottom */
@keyframes smokeAnimation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.smoke {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: smokeAnimation 4s infinite ease-in-out;
  z-index: 1;
}

.sliding-news-container {
  overflow: hidden;
  z-index: 50;
  height: fit-content; /* Adjust the height as needed */
}

.headline-slide {
  height: 100%;
  display: flex;
  align-items: center;
  animation: slideAnimation 30s linear infinite; /* Adjust the animation duration (in seconds) */
  white-space: nowrap;
}

.slide.active {
  animation-play-state: running;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.custompt {
  padding-top: 35rem;
}
.custompt2 {
  padding-top: 40rem;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.learnmoreD {
  position: absolute;
  top: 30%;
  left: 0%;
}
@media (max-width: 769px) {
  .learnmoreD {
    position: absolute;
    top: 30%;
    left: 0%;
    padding-block: 0.5rem;
  }
  .bigSize {
    background-size: 125%;
  }
  .textHolder {
    left: 20%;
  }
}
@media (max-width: 500px) {
  .learnmoreD {
    position: absolute;
    top: 30%;
    left: 0;
    padding-block: 0.5rem;
  }
}
@media (max-width: 720px) {
  .textHolder {
    top: 350px;
    left: 12%;
  }
}
@media (max-width: 800px) {
  .mobile-setup {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.mobile-setup > p {
  margin-bottom: 10px;
}
@media (min-width: 800px) {
  .clsImg {
    height: 80vh;
  }
}
@media (max-width: 1019px) {
  #displayDesktop {
    display: none !important;
  }
}

@media (min-width: 1020px) {
  #displayMobile {
    display: none !important;
  }
}

